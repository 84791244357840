import compareVersions from 'compare-versions';
import { codeToUrl } from 'constants/measurements';
import { makeTrans } from 'utils/translations';

export const getShortName = (name = '') => {
  const splitedName = name.split(' ');
  if (splitedName.length > 1) {
    return `${splitedName[0]} ${splitedName[splitedName.length - 1]}`;
  }
  return name;
};

export const getLatToString = lat => {
  const LAT_OPTIONS = {
    left: makeTrans('Left'),
    right: makeTrans('Right'),
    both: makeTrans('Both')
  };
  return Object.keys(LAT_OPTIONS).includes(lat) ? LAT_OPTIONS[lat] : undefined;
};

export const getEventName = (name, lat) =>
  getLatToString(lat) ? `${name} - ${window.i18next.t(getLatToString(lat))}` : name;

export const trim = data => {
  if (typeof data === 'string') {
    return data.trim();
  } else if (Array.isArray(data)) {
    return data.map(text => (typeof text === 'string' ? text.trim() : text));
  } else if (typeof data === 'object') {
    return Object.keys(data).reduce((p, k) => {
      const text = data[k];
      p[k] = typeof text === 'string' ? text.trim() : text;
      return p;
    }, {});
  }
  return data;
};

export const checkTargetApp = (version = 'noversion', location) => {
  const targets = [
    {
      name: 'reactnativeapp',
      type: 'mobile'
    }
  ];

  const result = targets.find(item => location[item.name]);
  return result ? `${result.type}-app/${version}` : `web/${version}`;
};

export const getUrlParam = (param, url = window.location.search) => {
  const searchParams = new URLSearchParams(url);
  return searchParams.get(param) || '';
};

export const goToPatient = (history, id) =>
  `${history.location.pathname.split('/').slice(0, 3).join('/')}/patients/${id}`;

export const nativeVersionMatch = (version, comparer) =>
  !(
    version &&
    comparer &&
    (!window.reactnativeappversion || !compareVersions.compare(window.reactnativeappversion, version, comparer))
  );

/**
 * Send an action/payload to React Native's Webview
 * @constructor
 * @param {string} action - getMeasurement (to get measurements), menu or tabMenu (for layout options).
 * @param {string} payload - ex. scale (for getMeasurement) or showHeader (for menu).
 * @param {string} version - Version to compare.
 * @param {string} comparer - Comparer operator.
 */
export const sendToNative = (action, payload, version = undefined, comparer = undefined) => {
  if (window.reactnativeapp && window.ReactNativeWebView) {
    if (!nativeVersionMatch(version, comparer)) {
      return false;
    }

    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        action,
        payload,
        url: window.location
      })
    );
  }
};

export const getParams = params => {
  const url = params.split('/');

  if (url.length < 4) {
    throw new Error(`Invalid params: ${window.location}`);
  }

  // old = /eprom/prom/<lang>/<pid>/0/<submission_id>
  // new = /eprom/prom/<lang>/patient/<pid>/submission/<submission_id>

  if (url.includes('submission')) {
    const [newLang, , newPatientID, , submissionId] = url;
    return [newLang, newPatientID, submissionId];
  }

  const [lang, patientID, , assessmentID] = url;
  return [lang, patientID, assessmentID];
};

/**
 * Check if there are items before the current one
 * @param  {Int} index item index on component
 * @param  {Boolean} expanded component is expanded
 * @param  {Array} pastAssessments List of past items
 * @return {Boolean} has items after or not
 */
export const hasItemsBefore = (index, expanded, pastAssessments) =>
  !expanded && index === 0 && pastAssessments.length > 1;

/**
 * Check if there are items after the current one
 * @param  {Int} index item index on component
 * @param  {Boolean} expanded component is expanded
 * @param  {Array} visibleItems List of visible items in component
 * @param  {Array} futureItems List of future items
 * @param  {Array} allItems List of all items
 * @param  {Array} previewItems List of preview items - items shown with the timeline not expanded
 * @return {Boolean} has items after or not
 */
export const hasItemsAfter = (index, expanded, visibleItems, futureItems, allItems, previewItems) =>
  !expanded && index + 1 === visibleItems.length && futureItems.length > 2 && allItems.length > previewItems.length;

export const noop = () => null;

export const openURLNewTab = url => {
  window.open(url, '_blank');
};

export const getRandomNumber = () => {
  const crypto = window.crypto || window.msCrypto;
  const array = new Uint32Array(1);
  return crypto.getRandomValues(array);
};

export const getRandomString = () => {
  const crypto = window.crypto || window.msCrypto;
  const value = crypto.getRandomValues(new Uint8Array(36));
  const stringValue = window.btoa(String.fromCharCode.apply(null, value));
  return stringValue.replace(/[/+]/g, '_');
};

export const genId = () => `-${getRandomString()}`;

export const hasSomeValues = items => Object.values(items).some(item => item?.value !== undefined);

export const idValidator = id => {
  if (!/^\d/.test(id)) {
    return id;
  }
  return `_${id}`;
};

export const getParamOrDefault = () => `${window.location.pathname}${window.location.search}`;

export const flattenParams = params => {
  let sorting = '';

  if (params.sorting?.field) {
    const operator = {
      DESC: '-',
      ASC: ''
    };
    sorting = `${operator[params.sorting.order]}${params.sorting.field}`;
  }

  return {
    q: params.q,
    page: params.page,
    ...params.filters,
    sorting
  };
};

export const formatDecimalCases = (value, amount = 2) => value?.toFixed(amount);

export const compareScaleValues = (scale, higherText, lowetText) =>
  scale && scale.best > scale.worst ? higherText : lowetText;

export const allDefined = (...args) => args.every(arg => arg !== undefined);

export const containsSubstring = (str, substrings) => substrings.some(substring => str.includes(substring));

export const goToPatientMetricsURL = (code, institutionSlug, patientUid, patientDiseaseId) =>
  `/hospital/${institutionSlug}/patients/${patientUid}/conditions/${patientDiseaseId}/measurements/${codeToUrl[code]}`;

export const getTimelineEventLaterality = laterality =>
  laterality ? ` - ${window.i18next.t(getLatToString(laterality))}` : '';
